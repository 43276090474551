import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["closeButton", "closeButtonIcon"]

  connect() {
    const studentInfo = document.getElementById("student__info")
    const studentInfoTitle = document.getElementById("student_info_title")

    // toggle close button to hide or show
    this.closeButtonTarget.addEventListener("click", (e) => {
      studentInfo.classList.toggle("hidden")

      // Use getComputedStyle to check if the element is hidden
      const isHidden = window.getComputedStyle(studentInfo).display === "none"

      if (isHidden) {
        // Student info is hidden, show "arrow up" icon
        studentInfoTitle.classList.remove("hidden")
        this.closeButtonIconTarget.classList.remove("bi-arrow-bar-up")
        this.closeButtonIconTarget.classList.add("bi-arrow-bar-down")
      } else {
        // Student info is visible, show "arrow down" icon
        studentInfoTitle.classList.add("hidden")
        this.closeButtonIconTarget.classList.remove("bi-arrow-bar-down")
        this.closeButtonIconTarget.classList.add("bi-arrow-bar-up")
      }
    })
  }

  disconnect() {
    this.closeButtonTarget.removeEventListener("click")
  }
}
